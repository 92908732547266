import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import PicsGrid from "../components/PicsGrid/PicsGrid";
import Description from "../components/Description/Description";

export const virtualShowroomImage = graphql`
  fragment virtualShowroomImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "virtual_showroom/hero.jpg" }) {
      ...virtualShowroomImage
    }
    image1: file(relativePath: { eq: "virtual_showroom/MELAMPO-EC_0339.jpg" }) {
      ...virtualShowroomImage
    }
    image2: file(relativePath: { eq: "virtual_showroom/MELAMPO-EC_1863.jpg" }) {
      ...virtualShowroomImage
    }
    image3: file(relativePath: { eq: "virtual_showroom/MELAMPO-EC_0388.jpg" }) {
      ...virtualShowroomImage
    }
    image4: file(relativePath: { eq: "virtual_showroom/Still-5.jpg" }) {
      ...virtualShowroomImage
    }
    image5: file(relativePath: { eq: "virtual_showroom/Still-4.jpg" }) {
      ...virtualShowroomImage
    }
    image6: file(relativePath: { eq: "virtual_showroom/Still-1.jpg" }) {
      ...virtualShowroomImage
    }
  }
`;

const VirtualShowroom = ({ data }) => (
  <Layout pageUrl="virtual-showroom">
    <SEO title="Virtual Showroom" />
    <Hero
      home={false}
      pageTitle="Virtual Showroom"
      heroImage={data.heroImage.childImageSharp.fluid}
      heroAlt="Virtual Showroom hero"
    />
    <PicsGrid
      pic1={data.image1.childImageSharp.fluid}
      alt1="Virtual showroom showcase"
      pic2={data.image2.childImageSharp.fluid}
      alt2="Virtual showroom showcase"
      pic3={data.image3.childImageSharp.fluid}
      alt3="Virtual showroom showcase"
    />
    <Description
      heading="L'immediatezza del virtuale, con velocità e precisione"
      description="Garantiamo ai nostri clienti la possibilità di realizzare, anche in tempi estremamente ridotti, virtual showroom pensati appositamente per il mondo dei buyer. Scatti indossati, Still Life, Ghost, con la possibilità di integrare la tecnologia delle immagini a 360°."
      wrappedHeroTitle
    />
    <PicsGrid
      pic1={data.image4.childImageSharp.fluid}
      alt1="Virtual showroom showcase"
      pic2={data.image5.childImageSharp.fluid}
      alt2="Virtual showroom showcase"
      pic3={data.image6.childImageSharp.fluid}
      alt3="Virtual showroom showcase"
    />
  </Layout>
);

export default VirtualShowroom;
